import React, { Component } from 'react'
import Title from '../components/title'
import Button from '../components/button'
import Input from '../components/input'
import NewLayout from "../layout/newLayout"
import { Formik } from "formik"
import { isArray as _isArray } from "lodash"
import styles from '../styles/scss/pages/partners.module.scss'
import PageContents from '../components/pageContents'
import SEO from "../components/seo"
import Imgix from 'react-imgix'

export default class Partners extends Component {
    constructor(props, context) {
        super(props, context)
        this.state = {
            submitted: false,
            authenticated: false
        }
    }

    render() {
        const page = this.props.pageContext.page
        return (
            <NewLayout>
                {page.seo.title && <h1 style={{ display: 'none' }}>{page.seo.title}</h1>}
                <SEO page={page} />
                <div className='d-flex flex-column flex-fill'>
                    <div className={styles.container}>
                        <Title size={40} value="WD-40® Brand Influencer Education Portal" />
                        {/* Unauthenticated state */}
                        {this.state.authenticated === false &&
                            <div className="col-md-6 offset-md-3 col-sm-12">
                                <Formik
                                    initialValues={{
                                        username: "",
                                        password: ""
                                    }}
                                    validate={values => {
                                        let errors = []
                                        if (values.username === "") {
                                            errors.push({ field: "username", message: "Username required" })
                                        }
                                        if (values.password === "") {
                                            errors.push({ field: "password", message: "Password required" })
                                        }
                                        return errors
                                    }}
                                    onSubmit={(values, actions) => {
                                        if (values.username === "wd40" && values.password === "blueandyellow") {
                                            this.setState({
                                                authenticated: true
                                            })
                                        }
                                    }}
                                >{(props) => (
                                    <form className={styles.form} onSubmit={props.handleSubmit}>
                                        <Title size={30} value="Please login to continue" />
                                        <Input
                                            name="username"
                                            title='Username'
                                            className={styles.loginInput}
                                            onChange={props.handleChange}
                                            onBlur={props.handleBlur}
                                            value={props.values.username} />
                                        <Input
                                            name="password"
                                            title='Password'
                                            type="password"
                                            className={styles.loginInput}
                                            onChange={props.handleChange}
                                            onBlur={props.handleBlur}
                                            value={props.values.password} />
                                        <div className={styles.errorContainer}>
                                            <ul>
                                                {_isArray(props.errors) && props.errors.filter((f) => { return props.touched[f.field] }).map((e) => {
                                                    return <li key={e.field}>{e.message}</li>
                                                })}
                                            </ul>
                                        </div>
                                        <Button
                                            text="Submit"
                                            blue
                                            submit
                                            className={styles.submit} />
                                    </form>
                                )}
                                </Formik>
                            </div>
                        }
                        {/* authenticated state */}
                        {this.state.authenticated === true &&
                            <div>
                                <PageContents content={page.content} />
                                <div className={styles.lineContainer}><hr /></div>
                                <div className="row">
                                    <div className='col-md-6'>
                                        <Title size={30} value="Content Publishing Checklist" />
                                        <p>Before you publish your video, social media or blog post, be sure to review the checklist to ensure everything has been approved, named correctly, and that the appropriate FTC disclosures have been made.</p>
                                        <br />
                                        <Button url={"https://files.wd40.com/pdf/WD40-Influencer_Checklist.pdf"} text='Download the Checklist' className={styles.button} target="_blank" rel="noopener noreferrer" />
                                    </div>
                                    <div className='col-md-6'>
                                        <Title size={30} value="Content Publishing Checklist" />
                                        <p>WD-40 Company follows FTC disclosure guidelines when working with partners like yourself to ensure full transparency about the relationship. Please download this handy guide to ensure all content you publish properly discloses your relationship with the company.</p>
                                        <Button url={"https://files.wd40.com/pdf/FTC_Guidelines.pdf"} text='Download the Guidelines' className={styles.button} target="_blank" rel="noopener noreferrer" />
                                    </div>
                                </div>
                                <div className={styles.lineContainer}><hr /></div>
                                <Title size={30} value="WD-40® Brand Uses By Industry" />
                                <p>Click on the links below to download WD-40 brand uses for your industry.</p>
                                <div className="row">
                                    <div className={'col-md-6 ' + styles.right}>
                                        <Button url={"https://files.wd40.com/pdf/WD40-Influencer_Database_Uses-Automotive_R_and_D.pdf"} text='Automotive' className={styles.button} target="_blank" rel="noopener noreferrer" />
                                        <br />
                                        <Button url={"https://files.wd40.com/pdf/WD40-Influencer_Database_Uses-Construction_RD.pdf"} text='Construction' className={styles.button} target="_blank" rel="noopener noreferrer" />
                                    </div>
                                    <div className={'col-md-6 ' + styles.left}>
                                        <Button url={"https://files.wd40.com/pdf/WD40-Influencer_Database_Uses-DIY.pdf"} text='DIY' className={styles.button} target="_blank" rel="noopener noreferrer" />
                                        <br />
                                        <Button url={"https://files.wd40.com/pdf/WD40-Influencer_Database_Uses-Farming_R_and_D.pdf"} text='Farming' className={styles.button} target="_blank" rel="noopener noreferrer" />
                                    </div>
                                </div>
                                <p><Button url={"https://files.wd40.com/pdf/WD40-Influencer_Database_Uses-MRO_R_and_D.pdf"} text='MRO' className={styles.button} target="_blank" rel="noopener noreferrer" /></p>
                                <div className={styles.lineContainer}><hr /></div>
                                <div className="row">
                                    <div className='col-md-6'>
                                        <Imgix src="/img/family-shot-mup.jpg" htmlAttributes={{alt: "WD-40 Multi-Use Product"}} width="100%" />
                                        <Title size={30} value="About WD-40® Multi-use Product" />
                                        <p><nobr>WD-40<sub>®</sub></nobr> Multi-Use Product is the #1 recommended multi-purpose lubricant. It stops squeaks, removes grease and grime, protects metal from rust, frees stuck parts, and displaces moisture. It’s available in the non-aerosol <nobr>WD-40<sub>®</sub></nobr> <nobr>Trigger Pro<sub>®</sub></nobr> can, the 18 oz. <nobr>WD-40<sub>®</sub></nobr> <nobr>Big Blast<sub>®</sub></nobr> with a wide area spray nozzle, the <nobr>WD-40</nobr> <nobr>EZ-REACH<sup>™</sup></nobr> with the 8" straw that bends and keeps its shape, the <nobr>WD-40<sub>®</sub></nobr> <nobr>Smart Straw<sub>®</sub></nobr> that <nobr>Sprays 2 Ways<sub>®</sub></nobr> and the <nobr>WD-40<sub>®</sub></nobr> One Gallon Container. For more information about <nobr>WD-40</nobr> Multi-Use Product,&nbsp;visit <a href="/"><nobr>WD40.com</nobr></a>.</p>
                                    </div>
                                    <div className='col-md-6'>
                                        <Imgix src="/img/family-shot-specialist.jpg" htmlAttributes={{alt: "WD-40 Specialist"}} width="100%" />
                                        <Title size={30} value="About WD-40® Specialist®" />
                                        <p>In 2011, WD-40 Company developed the <nobr>WD-40<sub>®</sub></nobr> <nobr>Specialist<sub>®</sub></nobr> line of products to help professionals in a number of industries, including automotive, farming, HVAC, and construction and skilled trades to get the job done right. These high-performance formulas solve tougher maintenance problems that require a lubricant, penetrant, grease, cleaner, degreaser or rust management solution. For more information about WD-40 Specialist products, visit <a href="https://www.wd40specialist.com/" target="_blank" rel="noopener noreferrer">WD40Specialist.com</a>.</p>
                                    </div>
                                </div>
                            </div>
                        }
                    </div>
                </div>
            </NewLayout>
        )
    }
}
